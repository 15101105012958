<template>
  <div id="main_content">
    <section class="banner banner-three">
      <div class="container">
        <div class="banner-content-wrap-two">
          <div class="banner-content text-center">
            <div>
              <img src="@/assets/mikmic/mikmic-1.png" class="wow pixZoomIn" alt="mpckup" />
            </div>

            <br />

            <div class="margin-button-div">
              <a href="https://system.mikmic.com/">
                <button
                  href="https://system.mikmic.com/"
                  class="custom-button pulse-button-custom"
                >Login</button>
              </a>

              <a href="https://system.mikmic.com/reg/new" style="margin-left: 10px;">
                <button
                  href="https://system.mikmic.com/reg/new"
                  class="custom-button pulse-button-custom"
                >Registrate</button>
              </a>
            </div>

            <br />

            <div class="banner-button-container">
              <a
                id="video-url"
                :href="$t('url_video')"
                class="play-btn popup-video wow pixFadeUp"
                data-wow-delay="0.6s"
              >
                <i class="ei ei-arrow_triangle-right"></i>
                <span>{{ $t('watch_video') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-shape-inner">
        <div class="circle-shape wow pixFadeRight">
          <img src="@/assets/media/background/circle4.png" data-parallax="{'x' : -50}" alt="circle" />
        </div>
        <div class="shape wow pixFadeLeft">
          <img src="@/assets/media/background/shape2.png" data-parallax="{'x' : 50}" alt="shape" />
        </div>
      </div>
    </section>

    <section class="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <div class="section-title text-center">
                <h3 class="sub-title wow pixFadeUp">{{$t('about_us')}}</h3>
                <h2 class="title wow pixFadeUp" data-wow-delay="0.3s">{{$t('our_history')}}</h2>
              </div>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{$t('about_us.p1.description')}}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{$t('about_us.p2.description')}}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{$t('about_us.p3.description')}}</p>
              <p
                class="description wow pixFadeUp text-justify"
                data-wow-delay="0.4s"
              >{{$t('about_us.p4.description')}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">{{ $t('operation_map') }}</h3>
          <br />

          <div class="banner-button-container">
            <img src="@/assets/mikmic/MXN_1.png" />
          </div>

          <br />

          <div>
            <p
              class="description wow pixFadeUp text-justify"
              data-wow-delay="0.4s"
            >{{ $t('map.p1.description') }}</p>

            <p
              class="description wow pixFadeUp text-justify"
              data-wow-delay="0.4s"
            >{{ $t('map.p2.description') }}</p>
          </div>

          <br />
          <h3 class="sub-title wow pixFadeUp">{{ $t('operation_map_jal') }}</h3>
          <br />

          <div>
            <p
              class="description wow pixFadeUp text-justify"
              data-wow-delay="0.4s"
            >{{ $t('operation_map_jal_zmg') }}</p>
          </div>
          <div class="banner-button-container">
            <img src="@/assets/mikmic/GDL_1.png" />
          </div>
          <br />
          <div>
            <p
              class="description wow pixFadeUp text-justify"
              data-wow-delay="0.4s"
            >{{ $t('operation_map_jal_pv') }}</p>
          </div>
          <div class="banner-button-container">
            <img src="@/assets/mikmic/PV_1.png" />
          </div>
          <br />
          <h3 class="sub-title wow pixFadeUp">{{ $t('operation_map_chi') }}</h3>
          <br />
          <div class="banner-button-container">
            <img src="@/assets/mikmic/CHIHUAHUA_1.png" />
          </div>
        </div>
      </div>
    </section>

    <section class="featured-four-ab">
      <div class="container">
        <div class="section-title text-center">
          <h3 class="sub-title wow pixFadeUp">{{ $t('download_now') }}</h3>
          <br />

          <h1 class="wow pixFadeUp">{{ $t('MIKMIC') }}</h1>
          <br />

          <div class="banner-button-container">
            <img src="@/assets/mikmic/mikmic_store_image.png" />
          </div>

          <div class="banner-button-container">
            <a href="https://play.google.com/store/search?q=mikmic&c=apps">
              <img src="@/assets/mikmic/google-store.png" class="button-store" />
            </a>

            <a
              href="https://apps.apple.com/mx/app/mikmic/id1673212928"
              class="button-store-position"
            >
              <img src="@/assets/mikmic/apple-store.png" class="button-store" />
            </a>
            <h6 class="wow pixFadeUp">{{ $t('register_mark') }}</h6>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "home",
  data() {
    return {};
  },
  mounted() {},
  computed: {}
};
</script>